@import "codemirror/lib/codemirror.css";
@import "codemirror/theme/material.css";

html {
  scroll-behavior: smooth;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

.cell-dataset td {
  padding-bottom: 5px !important;
  padding-top: 5px !important;
  border-bottom: 1px solid rgba(81, 81, 81, 1);
}
.cell-dataset select {
  padding: 10px;
}
.MuiList-padding {
  padding-top: 0px !important;
}
.sequence select {
  min-width: 60px;
}

.cell-dataset-step4 select {
  max-width: 100px !important;
  padding: 10px;
}
.cell-dataset-step4 input {
  padding: 10px;
}

.cell-dataset-step4 td {
  padding: 10px !important;
}
.cell-dataset-step4 p {
  min-width: 120px !important;
}
