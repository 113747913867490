/* Import Google Font - Poppins */
/* @import url("https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600;700&display=swap"); */
* {
  margin: 0;
  padding: 0;
}
::selection {
  color: #c5cae9;
  background: #4285f4;
}
.wrapper {
  width: 370px;
  margin: 45px auto 0;
}
.select-btn,
li {
  display: flex;
  align-items: center;
  cursor: pointer;
}
.select-btn {
  height: 65px;
  padding: 0 20px;
  /* font-size: 22px; */
  background: #c5cae9;
  border-radius: 7px;
  box-shadow: 0 10px 25px rgba(0, 0, 0, 0.1);
}
.select-btn i {
  /* font-size: 31px; */
  transition: transform 0.3s linear;
}
.wrapper.active .select-btn i {
  transform: rotate(-180deg);
}
.content {
  display: none;
  padding: 20px;
  margin-top: 15px;
  background: #c5cae9;
  border-radius: 7px;
  box-shadow: 0 10px 25px rgba(0, 0, 0, 0.1);
}
.wrapper.active .content {
  display: block;
}
.content .search {
  position: relative;
}
.search i {
  top: 50%;
  left: 15px;
  color: #999;
  /* font-size: 20px; */
  pointer-events: none;
  transform: translateY(-50%);
  position: absolute;
}
.search input {
  height: 50px;
  width: 100%;
  outline: none;
  /* font-size: 17px; */
  border-radius: 5px;
  padding: 0 20px 0 43px;
  border: 1px solid #b3b3b3;
}
.search input:focus {
  padding-left: 42px;
  border: 2px solid #4285f4;
}
.search input::placeholder {
  color: #bfbfbf;
}
.content .options {
  margin-top: 10px;
  max-height: 250px;
  overflow-y: auto;
  padding-right: 7px;
}
.options::-webkit-scrollbar {
  width: 7px;
}
.options::-webkit-scrollbar-track {
  background: #f1f1f1;
  border-radius: 25px;
}
.options::-webkit-scrollbar-thumb {
  background: #ccc;
  border-radius: 25px;
}
.options::-webkit-scrollbar-thumb:hover {
  background: #383333;
  color: #ccc;
}
.options li {
  height: 75px;
  padding-top: 20px;
  padding-bottom: 20px;
  border-bottom: solid 1px #504848;
  border-top: solid 1px #504848;
  /* font-size: 21px; */
}
.options li:hover,
li.selected {
  border-radius: 5px;
  background: #816161;
  color: #ccc;
}

.reddot {
  color: red;
  font-size: 20px;
}
.reddot-default {
  color: red;
  font-size: 20px;
  display: none;
}
